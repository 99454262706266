/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from 'react'
import classNames from 'classnames'

type Props = {
  url: string
  altText: string
  className?: string | string[]
}

const SocialLink: FC<Props> = ({ url, altText, className, children }) => (
  <a
    href={url}
    title={altText}
    className={classNames(className, 'no-hover')}
    target="_new"
  >
    {children}
  </a>
)

export default SocialLink
