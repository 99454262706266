import React, { FC, ReactElement } from 'react'
import { Link } from 'gatsby'

import Menu from './Menu'
import SocialLinkMenu from './SocialLinkMenu'
import LightSwitch from './LightSwitch'

import Coding from '../icons/coding.svg'

const Layout: FC = ({ children }): ReactElement => (
  <div className="flex flex-col min-h-screen overflow-hidden">
    <header className="pl-8 pr-5 py-8 relative z-20">
      <section className="container mx-auto flex flex-row items-center">
        <div className="w-8 mr-2 order-first static z-20">
          <Coding />
        </div>
        <h1 className="font-bold uppercase flex-grow order-first static z-20">
          <Link to="/" className="no-hover">
            Ellie Strejlau
          </Link>
        </h1>

        <Menu />
        <LightSwitch className="static z-20 order-1 md:ml-10" />
      </section>
    </header>
    <main className="px-8 py-5 flex-grow">{children}</main>
    <footer className="px-8 py-5 mt-16 md:pt-10 transform skew-y-3 bg-mono-switch2 -mb-10 shadow-inner">
      <section className="container mx-auto transform -skew-y-3 pb-10">
        <div className="text-sm pt-4 flex flex-col lg:flex-row md:pb-4 md:pt-0 lg:pt-4">
          <p className="flex-grow mt-2 mb-4 order-2 text-sm text-center md:text-left lg:order-none lg:my-0 lg:leading-8 md:mr-10 md:mb-0">
            &copy; {new Date().getFullYear()} Ellie Strejlau. All rights
            reserved, except disclaimers (below) and quotes (cited).
          </p>

          <SocialLinkMenu />
        </div>
        <hr />
        <p className="text-xs mt-2 mb-4">
          This site was built with{' '}
          <a href="//www.gatsbyjs.org/" target="_new">
            GatsbyJS
          </a>
          . The social media icons were made by{' '}
          <a
            href="//www.flaticon.com/authors/pixel-perfect"
            title="Pixel perfect"
          >
            Pixel perfect
          </a>
          . Most other icons made by{' '}
          <a
            href="https://www.flaticon.com/authors/smashicons"
            title="Smashicons"
            target="_new"
          >
            Smashicons
          </a>
          . Both can be found at{' '}
          <a href="https://www.flaticon.com/" title="Flaticon" target="_new">
            Flaticon
          </a>
          . Patterned backgrounds are from{' '}
          <a
            href="https://www.svgbackgrounds.com/"
            title="SVG Backgrounds"
            target="_new"
          >
            SVG Backgrounds
          </a>
          .
        </p>
      </section>
    </footer>
  </div>
)

export default Layout
