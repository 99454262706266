/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC, ReactElement } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import SchemaOrg from './SchemaOrg'

interface Props {
  title: string
  description?: string
  isBlogPost?: boolean
  lang?: string
  image?: string
  slug?: string
  datePublished?: string
}

const SEO: FC<Props> = ({
  title,
  description = '',
  isBlogPost = false,
  lang = 'en',
  image = '',
  slug = '',
  datePublished = '',
}): ReactElement => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            defaultImage
            siteUrl
            author {
              name
            }
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = `${site.siteMetadata.siteUrl}${
    image || site.siteMetadata.defaultImage
  }`
  const url = `${site.siteMetadata.siteUrl}${slug}`

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
      >
        <meta name="description" content={metaDescription} />
        <meta name="image" content={metaImage} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        <meta property="og:type" content={isBlogPost ? 'article' : 'website'} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={site.siteMetadata.social.twitter}
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={url}
        title={title}
        image={metaImage}
        description={metaDescription}
        datePublished={datePublished}
        canonicalUrl={site.siteMetadata.siteUrl}
        author={site.siteMetadata.author}
        defaultTitle={title}
      />
    </>
  )
}

export default SEO
