import React, { FC, ReactElement } from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import classNames from 'classnames'

import LightsOff from '../icons/light-off.svg'
import LightsOn from '../icons/light-on.svg'

interface Props {
  className?: string | string[]
}

interface SwitchProps {
  theme: string
  toggleTheme: CallableFunction
}

const LightSwitch: FC<Props> = ({ className }): ReactElement => {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }: SwitchProps): ReactElement => (
        <>
          <button
            aria-label="Toggle Dark/Light Mode"
            className={classNames(className, 'no-js:hidden w-8 h-8')}
            onClick={() => toggleTheme(theme === 'light' ? 'dark' : 'light')}
          >
            {theme === 'light' ? <LightsOff /> : <LightsOn />}
          </button>
          <noscript></noscript>
        </>
      )}
    </ThemeToggler>
  )
}

export default LightSwitch
