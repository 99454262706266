import React, { FC, useState } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import MenuIcon from '../icons/menu.svg'

const Menu: FC = () => {
  const [menuIsOpen, toggleMenu] = useState(
    // @ts-ignore
    typeof window !== 'undefined' ? window.__menu : false
  )

  const pages = [
    { url: '/about', name: 'About' },
    { url: '/blog', name: 'Thoughts' },
  ]

  return (
    <>
      <button
        aria-label="Toggle Mobile Menu"
        onClick={() => toggleMenu(!menuIsOpen)}
        className="w-8 h-8 ml-2 md:hidden order-last relative z-20 fill-switch"
      >
        <MenuIcon />
      </button>
      <nav
        aria-label="Main Nav"
        className={classNames(
          {
            'h-0': !menuIsOpen,
            'h-screen': menuIsOpen,
          },
          'inset-0 switch-height overflow-hidden text-2xl absolute',
          'md:w-auto md:h-auto md:static md:text-lg md:visible md:order-none'
        )}
      >
        <div className="pt-20 bg-mono-switch md:pt-0">
          <div className="h-screen bg-color-triangles shadow-inner px-8 mt-8 pt-8 transform -skew-y-3 md:skew-y-0 md:pt-0 md:px-0 md:flex md:bg-none md:h-auto md:mt-0 md:shadow-none">
            {pages.map((v, k) => {
              return (
                <Link
                  key={`menu-link-${k}`}
                  to={v.url}
                  // @ts-ignore
                  onClick={() => window.__setMenu(false)}
                  className="block transform skew-y-3 md:skew-y-0 py-6 md:ml-10 md:py-0"
                >
                  {v.name}
                </Link>
              )
            })}
            <a
              href="https://anchor.fm/warewolves"
              target="_blank"
              className="block transform skew-y-3 md:skew-y-0 py-6 md:ml-10 md:py-0"
              rel="noreferrer"
            >
              Podcast
            </a>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Menu
