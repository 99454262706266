import React, { ReactElement } from 'react'

import SocialLink from './SocialLink'

import Polywork from '../icons/polywork.svg'
import LinkedIn from '../icons/linkedin.svg'
import Twitter from '../icons/twitter.svg'
import Github from '../icons/github.svg'
import Gitlab from '../icons/gitlab.svg'
import Coffee from '../icons/coffee.svg'

const SocialLinkMenu = (): ReactElement => {
  return (
    <div className="flex order-1 justify-center mb-4 lg:mb-0 md:justify-start">
      <SocialLink
        url="//twitter.com/EllieStrejlau"
        altText="@EllieStrejlau on Twitter"
        className="w-8 h-8"
      >
        <Twitter />
      </SocialLink>
      <SocialLink
        url="//github.com/eroepken"
        altText="Ellie Strejlau on GitHub"
        className="w-8 h-8 ml-6 fill-switch"
      >
        <Github />
      </SocialLink>
      <SocialLink
        url="//gitlab.com/estrejlau"
        altText="Ellie Strejlau on GitLab"
        className="w-8 h-8 ml-6"
      >
        <Gitlab />
      </SocialLink>
      <SocialLink
        url="//www.polywork.com/elliestrejlau"
        altText="Ellie Strejlau on Polywork"
        className="w-8 h-8 ml-6"
      >
        <Polywork />
      </SocialLink>
      <SocialLink
        url="//www.linkedin.com/in/elliestrejlau"
        altText="Ellie Strejlau on LinkedIn"
        className="w-8 h-8 ml-6 -mt-px"
      >
        <LinkedIn />
      </SocialLink>
      <SocialLink
        url="//buymeacoff.ee/EllieStrejlau"
        altText="Buy me a coffee!"
        className="w-8 h-8 ml-6 pt-1"
      >
        <Coffee />
      </SocialLink>
    </div>
  )
}

export default SocialLinkMenu
